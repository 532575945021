@import "https://fonts.googleapis.com/icon?family=Material+Icons";
.main-content {
  min-height: calc(100vh - 129px); }

.text-outline-socgen {
  text-shadow: -1px 0 #e60028, 0 1px #e60028, 1px 0 #e60028, 0 -1px #e60028; }

.ceiling-icon {
  font-size: 15px;
  color: black;
  font-weight: bolder; }

.ceiling-icon-style {
  display: inline;
  margin-left: 8px; }
